
import iconPtBr from '../assets/img/languages/pt_br.png';
import iconEnUs from '../assets/img/languages/en_us.png';
import iconEsEs from '../assets/img/languages/es_es.png';

export default [
    {
        icon: iconPtBr,
        initials: "PT",
        text: {
            pt_br: "Português",
            en_us: "Portuguese",
            es_es: "Portugués"
        },
        value: "pt_br"
    }, {
        icon: iconEnUs,
        initials: "EN",
        text: {
            pt_br: "Inglês",
            en_us: "English",
            es_es: "Inglés"
        },
        value: "en_us"
    }, {
        icon: iconEsEs,
        initials: "ES",
        text: {
            pt_br: "Espanhol",
            en_us: "Spanish",
            es_es: "Español"
        },
        value: "es_es"
    }
]
