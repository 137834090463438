
import './style.css';

import Languages from '../../data/Languages';
import Translations from '../../data/Translations';

const LanguagesModal = ({currentLanguage, handleCurrentLanguageChange}) => {
    return (
        <div class="modal fade" id="languageModal" tabindex="-1" aria-labelledby="languageModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="languageModalLabel"> {Translations.selectLanguage[currentLanguage]} </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                        
                    <div class="modal-body">
                        {
                            Languages.map((lang, index) => {
                                return (
                                    <div className={`language-item ${lang.value === currentLanguage ? 'selected' : ''}`} key={index} data-bs-dismiss="modal" onClick={_ => handleCurrentLanguageChange(lang.value)}>
                                        <img src={lang.icon} alt={lang.initials} />
                                        <span> {lang.text[currentLanguage]} </span>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


export default LanguagesModal;