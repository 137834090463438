
import './style.css';

import Languages from '../../data/Languages';

const LanguagesTrigger = ({currentLanguage}) => {
    return (
        <div className='languages-trigger' data-bs-toggle="modal" data-bs-target="#languageModal">
            {
                Languages.filter(lang => lang.value === currentLanguage).map(lang => {
                    return (
                        <>
                            <img src={lang.icon} alt={lang.initials} />
                            <span> {lang.initials} </span>
                        </>
                    );
                })
            }
        </div>
    );
}


export default LanguagesTrigger;